/************************************ General >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/************************************ General >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/************************************ General >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/************************************ General >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/* all parameters */
* {
    box-sizing: border-box;
    outline: none;
    scroll-behavior: smooth;
    line-height: 1.15;
}

/* all links parameters */
a {
    text-decoration: none;
    color: #daedf2;
    font-weight: normal;
}

p {
    margin: 10px 0px;
}

/* main parameters */
body {
    background-color: #101437;
    margin: 0px;
    color: #daedf2;
    text-align: center;
    font-family: "montserrat_light";
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* fonts for code quotes */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< General *********************************/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< General *********************************/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< General *********************************/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< General *********************************/

/************************************* Fonts >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/************************************* Fonts >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/************************************* Fonts >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/************************************* Fonts >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

@font-face {
    font-family: "neon_absolute_sans_regular";
    src: url(./assets/fonts/neon_absolute_sans/NeonAbsoluteSans.ttf);
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: "neon_absolute_sans_light";
    src: url(./assets/fonts/neon_absolute_sans/newtypeface.ttf);
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: "montserrat_light";
    src: url(./assets/fonts/montserrat/Montserrat-Thin.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat_medium";
    src: url(./assets/fonts/montserrat/Montserrat-Light.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat_semi";
    src: url(./assets/fonts/montserrat/Montserrat-SemiBold.ttf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat_Bold";
    src: url(./assets/fonts/montserrat/Montserrat-Bold.ttf);
    font-weight: normal;
    font-style: normal;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Fonts **********************************/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Fonts **********************************/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Fonts **********************************/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Fonts **********************************/
